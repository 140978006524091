<template>
  <IchibanTemplate>
    <template v-slot:body>
      <div class="pa-4 wrapper">
        <div class="text-center">顯示 7 天內抽獎紀錄</div>
        <div class="text-center" v-if="!history.length">目前無記錄</div>
        <div>
          <v-card
            v-for="(row, i) in history"
            :key="i"
            color="#d8dee9"
            flat
            outlined
            rounded
            class="mt-1"
          >
            <v-card-title class="pa-2">
              <div>
                <span class="subtitle-1">
                  {{ mapIchiban[row.ichibanId]?.name }}
                  (<v-icon dense small>mdi-currency-usd</v-icon
                  >{{ row.plan.amount }})
                </span>
                <v-chip
                  v-if="row.rewards.reward"
                  class="ml-2"
                  color="red darken-2"
                  text-color="white"
                >
                  中獎
                </v-chip>
              </div>
              <v-spacer> </v-spacer>
              <v-chip
                v-if="
                  !!mapIchiban[row.ichibanId] &&
                  isAvailable(mapIchiban[row.ichibanId])
                "
                class="ml-2"
                color="#2fd5d8"
                @click="doRouting(row.ichibanId)"
              >
                <v-icon left> mdi-navigation-variant </v-icon>
                繼續抽
              </v-chip>
            </v-card-title>
            <v-card-text class="d-flex px-2 pt-0 pb-2">
              <div v-if="row.rewards?.reward">
                中奬品項：{{ row.rewards.reward }}
              </div>
              <v-spacer></v-spacer>
              <div class="caption">{{ row.createdAt }}</div>
            </v-card-text>
          </v-card>
        </div>
      </div>
    </template>
  </IchibanTemplate>
</template>

<script>
import liff from "@line/liff";
import util from "@/mixins/util";
import _ from "lodash";
import dayjs from "dayjs";
// import delay from "delay";
import { mapState } from "vuex";
import IchibanTemplate from "./IchibanTemplate.vue";

export default {
  name: "DialogIchiban",
  mixins: [util],
  props: [],
  components: { IchibanTemplate },
  data: () => ({
    records: [],
    history: [],
    result: null,
    dialog: { scratch: false, delivery: false },
    ichibans: [],
  }),
  async created() {
    if (!this.userID) {
      return this.$router.push({
        name: "Ichiban",
        query: { store: "@695yimoc" },
      });
    }

    try {
      await Promise.all([this.getIchibans(), this.getHistory()]);
    } catch (error) {
      alert(error);
      this.$router.go(-1);
    }
  },
  computed: {
    open: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    ...mapState("clientModule", {
      userID: (state) => state.userID,
      UID: (state) => state.UID,
      user: (state) => state.user,
      token: (state) => state.token,
      store: (state) => state.store,
      images: (state) => state.images,
    }),
    mapIchiban() {
      return _.keyBy(this.ichibans, "_id");
    },
  },
  methods: {
    async getIchibans() {
      const { data } = await this.axios.get(`/ichiban`, {
        params: { isOnline: true },
      });
      this.ichibans = data;
    },
    async getHistory() {
      const dateRange = [
        dayjs().add(-7, "day").format("YYYY-MM-DD"),
        dayjs().format("YYYY-MM-DD"),
      ];
      const { data } = await this.axios.get(`/ichibanRecord`, {
        params: { dateRange, userID: this.userID },
      });
      this.history = data.reverse();
    },
    handleDelivery() {
      this.dialog.delivery = true;
    },
    async reload() {
      await this.getHistory();
    },
    closeLiff() {
      liff.closeWindow();
    },
    openDialog(type) {
      this.dialog[type] = true;
    },
    async doRouting(id) {
      this.$vloading.show();

      try {
        const { data: ichiban } = await this.axios.get(`/ichiban/${id}`);

        if (this.isAvailable(ichiban)) {
          this.$router.push({
            name: "IchibanPage",
            params: { id: ichiban._id },
          });
          return;
        }
        await this.getIchibans();
        await this.$dialog.warning({
          persistent: false,
          title: "說明",
          text: `這個一番賞現在無法購買，即時完售或鎖定中，請稍後再試。`,
        });
      } catch (e) {}

      this.$vloading.hide();
    },
    isAvailable(ichiban) {
      if (!ichiban) return false;
      if (
        !ichiban.isAvailable ||
        ichiban.count === ichiban.sold ||
        ichiban.rewards.every((reward) => !!reward.hit)
      ) {
        return false;
      }
      if (ichiban.lockTo && !dayjs().isAfter(ichiban.lockTo)) {
        if (ichiban.lockToUser === this.userID) {
          return true;
        }
        return false;
      }
      return true;
    },
  },
};
</script>
<style lang="scss">
.v-btn.v-size--default {
  font-size: 16px !important;
}
.theme--light.v-icon:focus::after {
  opacity: 0;
}
</style>

<style lang="scss" scoped>
.wrapper {
  color: white;
}

.card {
  max-width: 400px;
  // width: 160px;
  padding: 10px;
  margin: 5px;
  background-color: #d2e7fa1a;
  font-weight: 500;
  font-size: 20px;

  background-color: #172c3b;
  &__body {
    display: flex;
    justify-content: space-between;
  }
}

.v-card {
  .text-position {
    font-size: 1.1rem;
  }
  .left {
    // background-color: #444;
    background-color: white;
    color: #000;
  }
  .win {
    background-color: #d4af37;
    color: #222;
    .text-reward {
      font-weight: bolder;
    }
  }
  .lose {
    background-color: #ccc;
    color: #222;
  }
}
</style>
